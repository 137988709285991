@import '../theme-bootstrap';

.mpp {
  &__regimen-quiz {
    // Put the tabs up into the header:
    // Note: It was decided to make the header smaller and not force the tabs up
    // into it for mobile, contrary to the designs.
    margin-top: 20px;
    position: relative;
    top: 1px;
    @include breakpoint($medium-up) {
      margin-top: -44px;
      position: static;
      top: auto;
    }
    &__tabs {
      @include pie-clearfix;
      max-width: 622px;
      margin: 0 auto;
    }
    &__tab {
      float: left;
      width: 50%;
      position: relative;
      top: 0;
      z-index: 1;
      &__inner {
        @include font-padding(text, 16px, 43px);
        @include font--text;
        text-align: center;
        font-size: 16px;
        display: block;
        margin: 0 3px;
        background-color: $color-dark-gray;
        color: $color-white;
        @include breakpoint($medium-up) {
          margin: 0 2px;
        }
      }
      &:first-child .mpp__regimen-quiz__tab__inner {
        margin-left: 0;
      }
      &:last-child .mpp__regimen-quiz__tab__inner {
        margin-right: 0;
      }
    }
  }
  .regimen-quiz {
    display: none;
    background-color: #f5f5f3;
    &__content {
      border-top: none;
    }
  }
  .mpp-sort {
    background-color: #f5f5f3;
    @include breakpoint($medium-up) {
      background-color: transparent;
    }
  }
  &--has-regimen .mpp__content {
    border-top: solid 1px $color-black;
    @include breakpoint($medium-up) {
      border-top: none;
    }
  }
  &--regimen-view {
    .regimen-quiz {
      display: block;
    }
    .mpp-sort {
      display: none;
    }
  }
  .mpp__regimen-quiz,
  .mpp__content {
    @include transition(opacity, 200ms);
  }
  &--regimen-loading,
  &--regimen-saving {
    .mpp__regimen-quiz,
    .mpp__content {
      opacity: 0.7;
      pointer-events: none;
    }
    .mpp__regimen-quiz__tab__results-text {
      display: none;
    }
  }
  .mpp__regimen-quiz__tab__loading-text,
  .mpp__regimen-quiz__tab__saving-text {
    display: none;
  }
  &--regimen-loading .mpp__regimen-quiz__tab__loading-text {
    display: inline;
  }
  &--regimen-saving .mpp__regimen-quiz__tab__saving-text {
    display: inline;
  }
  .product-grid-wrapper {
    // The only borders on the MPP come from the content header or sort wrapper
    border: none;
  }
  .product-grid {
    .regimen-results-header__text {
      @include breakpoint($medium-up) {
        display: none;
      }
    }
  }
}

// Tabs:
.mpp__regimen-quiz__tab__inner--results,
.mpp--regimen-view .mpp__regimen-quiz__tab__inner--all {
  @include font-smoothing(antialiased);
  //font-style: italic;
  font-family: $font--text--italic;
  background-color: $color-dark-gray;
  color: $color-white;
  cursor: pointer;
  border: none;
}

.mpp__regimen-quiz__tab__inner--all,
.mpp--regimen-view .mpp__regimen-quiz__tab__inner--results {
  @include font-smoothing(auto);
  font-style: normal;
  background-color: #f5f5f3;
  color: $color-black;
  border: solid 1px $color-black;
  border-bottom: none;
}

@include breakpoint($medium-up) {
  .mpp__regimen-quiz__tab__inner--all {
    background-color: $color-white;
  }
}

// Results:
.regimen-results-header {
  @include pie-clearfix;
  max-width: $max-width-large;
  margin: 0 auto;
  display: none;
  width: 100%;
  .mpp--regimen-view.mpp--regimen-filtered & {
    @include breakpoint($medium-up) {
      display: block;
    }
  }
  .mpp--regimen-view.mpp--regimen-filtered--0 & {
    @include breakpoint($medium-up) {
      display: none;
    }
  }
  .mpp--regimen-view.mpp--regimen-filtered--1 & {
    &__text--ancillary {
      @include breakpoint($medium-up) {
        display: none;
      }
    }
  }
  &__text {
    @include font--heading;
    text-align: center;
    font-size: 16px;
    margin: 0;
    padding: 20px 0 0;
    @include breakpoint($medium-up) {
      font-size: 21px;
      float: left;
      padding: 60px 0 15px;
      &--primary {
        width: 33.333%;
        border-left: solid 1px $color-black;
        border-right: solid 1px $color-black;
      }
      &--ancillary {
        width: 66.667%;
      }
    }
  }
}

.mpp--regimen-filtered {
  &.mpp--regimen-view {
    .product-grid__item--primary-regimen-result {
      // This is what the designs do, but we're not using pngs for the images
      // so you can see a bunch of white around them. Doing a border instead.
      // background: #f2faf4;
      // background: rgba($color-green, 0.1);
      border-left: solid 1px $color-black;
      border-right: solid 1px $color-black;
    }
  }
}
